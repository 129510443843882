// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'trix'
import 'trix/dist/trix.css'
import { Luminous } from 'luminous-lightbox'
import 'luminous-lightbox/dist/luminous-basic.css'

// Bootstrap
import $ from 'jquery'
global.$ = jQuery
import 'at.js'
import 'jquery.caret'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import 'at.js/dist/css/jquery.atwho.css'
import 'admin-lte'
import 'admin-lte/dist/css/adminlte.min'

// styles
import '../styles/application.scss'
import '@fortawesome/fontawesome-free/css/all.css'

// Load Stimulus controllers
import '../controllers'

require('@rails/ujs').start()
// require('turbolinks').start()
require('../channels')

require("@rails/activestorage").start()
import './direct_uploads'

$(document).ready(() => {
  $('body').tooltip({
    selector: '[data-toggle="tooltip"]',
    container: 'body'
  })

  $('body').popover({
    selector: '[data-toggle="popover"]',
    container: 'body',
    html: true,
    trigger: 'hover'
  })

  $(".alert-dismissible").fadeTo(2000, 500).slideUp(500, function(){
    $(".alert-dismissible").alert('close')
  })

  $('.lightbox').each(function() {
    new Luminous($(this).get()[0])
  })

  $('#new_post').submit(function(e) {
    if (!$("textarea[name='post[content]']", this).val()) {
      return false
    }
  })

  $(".new_comment").each(function(){
    $(this, "textarea[name='comment[content]']").keypress(function (e) {
      if (event.keyCode == 13) {
        if (!event.shiftKey) {
          if ($.trim($(this).find("textarea[name='comment[content]']").val())) {
            var form = $(this, 'form');
            var url = form.attr('action');

            $.ajax({
              type: "POST",
              url: url,
              data: form.serialize()
            });
            e.preventDefault();
            $(this).find("textarea[name='comment[content]']").prop('disabled', true);
            return true;
          } else {
            return false;
          }
        }
      }
    })
  })

  $(".reply_comment").click(function(e){
    var post_id = $(this).data("post-id");
    var comment_user_name = $(this).data("comment-user-name");
    var comment_textarea = $("#post-"+post_id+" .new_comment textarea");
    var text = comment_textarea.val();
    if (text.slice(text.length - 1).trim()) {
      $("#post-"+post_id+" .new_comment textarea").val(text+" "+"@"+comment_user_name+" ");
    } else {
      $("#post-"+post_id+" .new_comment textarea").val(text+"@"+comment_user_name+" ");
    };
    $("#post-"+post_id+" .new_comment textarea").focus().val(comment_textarea.val());
    e.preventDefault();
  })

  $(".at-js").each(function () {
    self = $(this)
    self.atwho({
      at: "@",
      headerTpl: "<div class='at-user'>想要@誰</div>",
      searchKey: "name",
      displayTpl: "<li class='mention-user'><img src=${avatar_url} class='avatar img-circle'></img>${name}</li>",
      insertTpl: '@${name}',
      limit: 5,
      callbacks: {
        remoteFilter: function (query, callback) {
          if (query.length < 1) {
            return false;
          } else {
            $.getJSON("/mentions.json", {q: query, group_id: self.data("group_id")}, function(data) {
              console.log(data);
              callback(data.data)
            });
          }
        }
      }
    }).atwho({
      at: "#",
      headerTpl: "<div class='at-hashtag'>選擇或自動建立標籤</div>",
      searchKey: "name",
      displayTpl: "<li class='mention-hashtag'>${name}</li>",
      insertTpl: '#${name}',
      limit: 5,
      callbacks: {
        remoteFilter: function (query, callback) {
          if (query.length < 1) {
            return false;
          } else {
            $.getJSON("/hashtags.json", {q: query}, function(data) {
              console.log(data);
              callback(data.data)
            });
          }
        }
      }
    })
  })

  window.autogrow = function(textarea){
    var adjustedHeight=textarea.clientHeight;
    adjustedHeight=Math.max(textarea.scrollHeight,adjustedHeight);
    if (adjustedHeight>textarea.clientHeight){
      textarea.style.height=adjustedHeight+'px';
    }
  }
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
